import { UploadFileInfo } from "@progress/kendo-react-upload";
import React from "react";
import { GenericActionType } from "src/context/generic-actions.type";
import { SelectedFormType } from "src/context/SelectedForm/selected-form-reducer";
import { IFormEntryModel } from "src/interfaces/form-entry.interface";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { IFormFilesInterface } from "src/interfaces/forms/Shared/form-files.interface";
import classes from "./FilesList.module.scss";

interface GeneralFormType {
    setFormDataFields: (form: any) => void;
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
    setFiles: (value: UploadFileInfo[]) => void;
    files: UploadFileInfo[];
    formSettings?: IFormSettingModel,
    formEntry?: IFormEntryModel,
    formDataFields: any,
    fieldName: string,
    isAdminPreview?: boolean,
    setStateChanged?: (value: boolean) => void,
    onFileRemoved?: (file: IFormFilesInterface) => void,
}

export default function FilesList(props: GeneralFormType) : JSX.Element {
    const { setFormDataFields, selectedFormDispatch, setFiles,
        files, formSettings, formEntry, formDataFields, fieldName, isAdminPreview, setStateChanged, onFileRemoved } = props;

    function removeFile(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        fileId: string,
        fieldName: string,
        filesFieldArray?: Array<IFormFilesInterface>) {
        event.preventDefault();
        if (formEntry?.accessCode) {
            const item = filesFieldArray?.find(x => x.id === fileId);
            if (item) {
                setFormDataFields({
                    ...formDataFields,
                    [fieldName]: filesFieldArray ? [...filesFieldArray.filter(x => x.id != fileId)] : [],
                });

                setFiles(files?.filter(x => x.uid != fileId));

                if(setStateChanged) {
                    setStateChanged(true);
                }

                onFileRemoved?.(item);
            }
        }
    }

    return <>
        <ol>
            {formDataFields?.[fieldName]?.map((item: IFormFilesInterface) => {
                return <li className={classes.filesList} key={item.id!}>
                    {item.extension == '.pdf' ?
                        <i className={classes.fa + " fas fa-file-pdf"}></i>
                        : item.extension == '.doc' ?
                        <i className={classes.fa + " fa-solid fa-file-word"}></i>
                        :
                        <i className={classes.fa + " fa fa-regular fa-file"}></i>
                    }
                    <p className={classes.itemData} >{item.name}</p>
                    { (!formSettings?.isReadOnly || !formEntry?.isUploadReadOnly) && !isAdminPreview &&
                        <button disabled={formEntry?.submissionStatus == "Submitted" ?true:false} onClick={(e) => removeFile(
                            e,
                            item.id,
                            fieldName,
                            formDataFields?.[fieldName],
                        )}>
                           
                           <i className="fa fas fa-times"></i> 
                            
                        </button>
                    }
                </li>
            })}
        </ol>
    </>
}
