/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { globalConfig } from "src/configuration/config";
import { IDialog } from "src/interfaces/messages/dialogs/dialog.interface";
import { IViewMessage } from "src/interfaces/messages/view-message.interface";
import { IAlert } from "src/interfaces/alert.interface";
import { User } from "oidc-client";

export const SIGNALR_CHATS = {
  UPDATE_RECEIVERS_UNREAD_DIALOGS_COUNT: "onUpdateReceiversUnreadDialogsCount",
  UPDATE_ADMINS_UNREAD_DIALOGS_COUNT: "onUpdateAdminsUnreadDialogsCount",
  ALERT_CREATE: "onAlertCreate",
  CREATE_DIALOG: "onCreateDialog",
  SUBMIT_FORM_ENTRY: "onSubmitFormEntry",
  DELETE_FORM_ENTRY: "onDeleteFormEntry",
  ADD_MESSAGE: "addMessage",
};

interface SignalrEventSubscription {
  eventType: string;
  subscribers: { (...args: any[]): void }[];
}

export default class SignalrService {
  static instance: SignalrService;

  private _formEntryConnection: HubConnection | undefined;
  private _chatConnection: HubConnection | undefined;
  private subscriptions: SignalrEventSubscription[] = [];

  public running = false;

  static getInstance() {
    if (SignalrService.instance == null) {
      SignalrService.instance = new SignalrService();
    }

    return this.instance;
  }

  public start(accessToken: string | undefined): void {
    if (!accessToken || this.running) {
      return;
    }

    try {
      this.startChatConnection(accessToken);
      this.startFormEntryConnection(accessToken);
      this.running = true;
    } catch {
      this.running = false;
    }
  }

  private startFormEntryConnection(token: string): void {
    this._formEntryConnection = new HubConnectionBuilder()
      .withUrl(`${globalConfig.get().signalRApiRoot}form-entry`, {
        accessTokenFactory: () => token,
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this._formEntryConnection.on(
      SIGNALR_CHATS.SUBMIT_FORM_ENTRY,
      (formEntryId: number) =>
        this.handleEvent(SIGNALR_CHATS.SUBMIT_FORM_ENTRY, formEntryId)
    );

    this._formEntryConnection.on(SIGNALR_CHATS.DELETE_FORM_ENTRY, () =>
      this.handleEvent(SIGNALR_CHATS.DELETE_FORM_ENTRY)
    );

    this._formEntryConnection.start();
  }

  public startChatConnection(token: string): void {
    this._chatConnection = new HubConnectionBuilder()
      .withUrl(`${globalConfig.get().signalRApiRoot}chat`, {
        accessTokenFactory: () => token,
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this._chatConnection.on(
      SIGNALR_CHATS.UPDATE_RECEIVERS_UNREAD_DIALOGS_COUNT,
      () =>
        this.handleEvent(SIGNALR_CHATS.UPDATE_RECEIVERS_UNREAD_DIALOGS_COUNT)
    );

    this._chatConnection.on(
      SIGNALR_CHATS.UPDATE_ADMINS_UNREAD_DIALOGS_COUNT,
      () => this.handleEvent(SIGNALR_CHATS.UPDATE_ADMINS_UNREAD_DIALOGS_COUNT)
    );

    this._chatConnection.on(
      SIGNALR_CHATS.ALERT_CREATE,
      (userNotification: IAlert) =>
        this.handleEvent(SIGNALR_CHATS.ALERT_CREATE, userNotification)
    );

    this._chatConnection.on(SIGNALR_CHATS.CREATE_DIALOG, (dialog: IDialog) =>
      this.handleEvent(SIGNALR_CHATS.CREATE_DIALOG, dialog)
    );

    this._chatConnection.on(
      SIGNALR_CHATS.ADD_MESSAGE,
      (message: IViewMessage) =>
        this.handleEvent(SIGNALR_CHATS.ADD_MESSAGE, message)
    );

    this._chatConnection.start();
  }

  public stop(): void {
    if (this._chatConnection) {
      this._chatConnection.stop();
      this._chatConnection = undefined;
    }

    if (this._formEntryConnection) {
      this._formEntryConnection.stop();
      this._formEntryConnection = undefined;
    }
  }

  public async subscribeToEvent(
    eventType: string,
    handler: (...args: any[]) => void
  ): Promise<void> {
    if (!this.running) {
      return;
    }

    const target = this.subscriptions.find(
      (item) => item.eventType === eventType
    );
    if (!target) {
      this.subscriptions = [{ eventType, subscribers: [handler] }];
    } else {
      target.subscribers = [...target.subscribers, handler];
    }
  }

  private handleEvent(type: string, ...args: any[]): void {
    const subscription = this.subscriptions.find(
      (item) => item.eventType === type
    );
    if (!subscription || !subscription.subscribers?.length) {
      return;
    }

    subscription.subscribers.forEach((handler) => handler(...args));
  }
}
