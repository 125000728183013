import Footer from "./components/Footer/Footer";
import React, { Fragment, useEffect, useState } from "react";
import { store } from "./storage/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AxiosInterceptor } from "./axios-instance";
import { Router } from "./components";
import { globalConfig, globalConfigUrl } from "./configuration/config";
import { SharedProvider } from "./context/shared/shared-context";
import "./configs/fontAwesomeConfig";
import "./configs/i18nConfig";
import "./configs/timeAgoConfig";
import "./styles/style.scss";
import "font-awesome/css/font-awesome.min.css";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import AuthService from "./services/authentication.service";
import { localeAppSettings } from "./constants/locale-constants";
import { checkLogin } from "./App.helper";

export default function App() {
  const [config, setConfig] = useState<Record<string, unknown>>();
  const [initialized, setInitialized] = useState<boolean>();

  useEffect(() => {
    fetch(location.origin + globalConfigUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response: any) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(async (data) => {
        globalConfig.set(data);
        setConfig(data);

        const authService = AuthService.getInstance();

        setInitialized(true);

        const token = await authService.getToken();
        if (!token) {
          return;
        }

        checkLogin(authService, data, token);

        window.addEventListener('visibilitychange', async () => {
          if (document.visibilityState === 'hidden') {
            return;
          }

          checkLogin(authService, data, token);
        });
      });
  }, []);

  return (
    <Provider store={store} >
    <Fragment>
      <LocalizationProvider language={localeAppSettings.language}>
        <IntlProvider locale={localeAppSettings.locale}>
          {config && (
            <>
              <BrowserRouter>
                <SharedProvider>
                  {initialized ? <AxiosInterceptor>
                    <Router />
                  </AxiosInterceptor> : <></>}
                </SharedProvider>
              </BrowserRouter>
              <Footer />
            </>
          )}
        </IntlProvider>
      </LocalizationProvider>
    </Fragment>
    </Provider>
  );
}
