export type IFreeportFormFirstSectionInterface = {
    TaxYear?: string;
    ApplicantOwnedPropertyJan1?: boolean;
    individual?: boolean;
    corporation?: boolean;
    partnership?: boolean;
    other?: boolean;
    OwnershipTypeOther?: string;
    NameOfPropertyOwner?: string;
    PrimaryPhoneNumber?: string;
    EmailAddress?: string;
    PersonalId?: string;
    PhysicalAddressLine1?: string;
    PhysicalAddressLine2?: string;
    PhysicalAddressCity?: string;
    PhysicalAddressState?: string;
    PhysicalAddressZip?: string;
    MailingAddressLine1?: string;
    MailingAddressLine2?: string;
    MailingAddressCity?: string;
    MailingAddressState?: string;
    MailingAddressZip?: string;
    ProductOwnerType?: string;
    ProductOwnerTypeOther?: string;
}

export const FreeportFormFirstSectionInterface = (): IFreeportFormFirstSectionInterface => ({
    TaxYear: undefined,
    ApplicantOwnedPropertyJan1: undefined,
    individual: undefined,
    corporation: undefined,
    partnership: undefined,
    other: undefined,
    OwnershipTypeOther: undefined,
    NameOfPropertyOwner: undefined,
    PrimaryPhoneNumber: undefined,
    EmailAddress: undefined,
    PersonalId: undefined,
    PhysicalAddressLine1: undefined,
    PhysicalAddressLine2: undefined,
    PhysicalAddressCity: undefined,
    PhysicalAddressState: undefined,
    PhysicalAddressZip: undefined,
    MailingAddressLine1: undefined,
    MailingAddressLine2: undefined,
    MailingAddressCity: undefined,
    MailingAddressState: undefined,
    MailingAddressZip: undefined,
    ProductOwnerType:undefined,
    ProductOwnerTypeOther:undefined
})