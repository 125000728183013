import type { AxiosError, AxiosResponse } from "axios";
import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { getSettings } from "src/api/settings-api";
import Welcome from "src/components/Welcome/Welcome";
import { useSharedContext } from "src/context/shared/shared-context";
import {
  clearUserDataHelper,
  getUserHelper,
  setLoading,
  temporaryError,
} from "src/context/shared/shared-context.helper";
import Download from "src/pages/Shared/Download/Download";
import { settingsService } from "src/services/settings.service";
import CustomLoader from "../CustomLoader/CustomLoader";
import BPPForm from "../../pages/Home/components/Forms/BPPForm/BPPForm";
import AgForm from "src/pages/Home/components/Forms/AgForm/AgForm";
import HomesteadExemptionForm from "../../pages/Home/components/Forms/HomesteadExemptionForm/HomesteadExemptionForm";
import {
  AddressChangeFormName,
  AgFormName,
  BPPFormName,
  DisabledVetFormName,
  FreeportFormName,
  HomesteadExemptionFormName,
  TemporaryExemptionPropertyDamagedbyDisasterFormName,
  TimberlandAppraisalFormName,
  WildlifeManagementFormName,
} from "../../constants/form-names-constants";
import SignalrService from 'src/services/signalr.service';
import AuthService from 'src/services/authentication.service';
import BPPFormEntry from "../../pages/Home/components/FormEntries/BPPFormEntry";
import AgFormEntry from "../../pages/Home/components/FormEntries/AgFormEntry";
import HomesteadExemptionFormEntry from "../../pages/Home/components/FormEntries/HomesteadExemptionFormEntry";
import FreeportForm from "src/pages/Home/components/Forms/FreeportForm/FreeportForm";
import FreeportFormEntry from "src/pages/Home/components/FormEntries/FreeportFormEntry";
import WildlifeManagementForm from "src/pages/Home/components/Forms/WildlifeManagementForm/WildlifeManagementForm";
import WildlifeManagementFormEntry from "src/pages/Home/components/FormEntries/WildlifeManagementFormEntry";
import AddressChangeForm from "src/pages/Home/components/Forms/AddressChangeForm/AddressChangeForm";
import AddressChangeFormEntry from "src/pages/Home/components/FormEntries/AddressChangeFormEntry";
import ExemptionPropertyDamagedFormEntry from "src/pages/Home/components/FormEntries/ExemptionPropertyDamagedFormEntry";
import ExemptionPropertyDamagedForm from "src/pages/Home/components/Forms/ExemptionPropertyDamagedForm/ExemptionPropertyDamagedForm";
import DisabledVetForm from "src/pages/Home/components/Forms/DisabledVetForm/DisabledVetForm";
import DisabledVetFormEntry from "src/pages/Home/components/FormEntries/DisabledVetFormEntry";
import TimberlandAppraisalFormEntry from "src/pages/Home/components/FormEntries/TimberlandAppraisalFormEntry";
import TimberlandAppraisalForm from "src/pages/Home/components/Forms/TimberlandAppraisalForm/TimberlandAppraisalForm";

const Admin = lazy(() => import("src/pages/Admin/Admin"));
const Alerts = lazy(() => import("src/pages/Admin/Alerts/Alerts"));
const Home = lazy(() => import("src/pages/Home/Home"));
const NotFound = lazy(() => import("src/pages/NotFound/NotFound"));
const Dashboard = lazy(() => import("src/pages/Admin/Dashboard/Dashboard"));
const Forms = lazy(() => import("src/pages/Admin/Forms/Forms"));
const FormPermissions = lazy(
  () => import("src/pages/Admin/Permissions/FormPermissions/FormPermissions")
);
const SitePermissions = lazy(
  () => import("src/pages/Admin/Permissions/SitePermissions/SitePermissions")
);
const Settings = lazy(() => import("src/pages/Admin/Settings/Settings"));

const Dialogs = lazy(() => import("src/pages/Shared/Messages/Dialogs"));
const AccountActivation = lazy(
  () => import("src/pages/Shared/Messages/Dialogs")
);
const SignIn = lazy(() => import("src/pages/Home/components/SignIn/SignIn"));
const ResetPassword = lazy(
  () => import("src/pages/Home/components/ResetPassword/ResetPassword")
);
const FormEntry = lazy(
  () => import("src/pages/Home/components/FormEntry/FormEntry")
);
const FormEntries = lazy(
  () => import("src/pages/Home/components/FormEntries/FormEntries")
);
const Users = lazy(() => import("src/pages/Admin/Users/Users"));
const UserGroup = lazy(() => import("src/pages/Admin/UserGroup/UserGroup"));
const AuthCallback = lazy(() => import("src/pages/AuthCallback/AuthCallback"));

export default function Router(): JSX.Element {
  const authenticationService = AuthService.getInstance();
  const signalrService = SignalrService.getInstance();

  const { sharedState, sharedDispatch } = useSharedContext();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const { setSettings } = settingsService();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLoading(sharedDispatch, true);

    getSettings()
      .then((result: AxiosResponse) => {
        setSettings(result.data);
        setLoading(sharedDispatch);
      })
      .catch((err: AxiosError) =>
        temporaryError(sharedDispatch, err.response?.data as any)
      );
  }, []);

  useEffect(() => {
    if (["/signin-callback", "/signout-callback"].includes(location.pathname)) {
      return;
    }

    const redirect = async () => await authenticationService.user()
      .then(async user => {
        setIsAuthenticated(!!user);
        setIsAdmin(!!user && user.isAdmin);

        if (
          !user &&
          !authenticationService.isUnathenticatedPath(location.pathname, location.search)
        ) {
          return navigate("/login");
        }

        if (
          user?.isAdmin &&
          ((location.pathname.indexOf("/admin") < 0 &&
            location.pathname.indexOf("/home/download") < 0) ||
            location.pathname === "/admin") &&
          location.pathname !== "/signin-callback" &&
          location.pathname !== "/signout-callback"
        ) {
          return navigate("/admin/dashboard");
        }

        if (
          !!user &&
          !user.isAdmin &&
          location.pathname.indexOf("/home") < 0 &&
          location.pathname.indexOf("/signout-callback") < 0
        ) {
          return navigate("/home");
        }

        const token = await authenticationService.getToken();
        if (token) {
          getUserHelper(sharedDispatch);
          signalrService.start(token);
        } else {
          clearUserDataHelper(sharedDispatch);
        }
      });

    redirect();
  }, [
    location.pathname,
    navigate,
    sharedState.user?.id,
  ]);

  return (
    <Suspense fallback={<CustomLoader />}>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/login" element={<SignIn />} />
          <Route path="/home" element={<Welcome />} />
          <Route path="/signin-callback" element={<AuthCallback />} />
          <Route path="/home/forms/:id" element={<FormEntry />} />
          <Route
            path={`/home/forms/${BPPFormName}/`}
            element={<BPPForm />}
          />
          <Route
            path={`/home/forms/${BPPFormName}/form-entry/:formEntryIdParams`}
            element={<BPPFormEntry />}
          />
          <Route
            path={`/home/forms/${AgFormName}/`}
            element={<AgForm />}
          />
          <Route
            path={`/home/forms/${AgFormName}/form-entry/:formEntryIdParams`}
            element={<AgFormEntry />}
          />
          <Route
            path={`/home/forms/${FreeportFormName}/`}
            element={<FreeportForm />}
          />
          <Route
            path={`/home/forms/${FreeportFormName}/form-entry/:formEntryIdParams`}
            element={<FreeportFormEntry />}
          />
          <Route
            path={`/home/forms/${WildlifeManagementFormName}/`}
            element={<WildlifeManagementForm />}
          />
          <Route
            path={`/home/forms/${WildlifeManagementFormName}/form-entry/:formEntryIdParams`}
            element={<WildlifeManagementFormEntry />}
          />
          <Route
            path={`/home/forms/${AddressChangeFormName}/`}
            element={<AddressChangeForm/>}
          />
          <Route
            path={`/home/forms/${AddressChangeFormName}/form-entry/:formEntryIdParams`}
            element={<AddressChangeFormEntry />}
          />
          <Route
            path={`/home/forms/${HomesteadExemptionFormName}/`}
            element={<HomesteadExemptionForm />}
          />
          <Route
            path={`/home/forms/${HomesteadExemptionFormName}/form-entry/:formEntryIdParams`}
            element={<HomesteadExemptionFormEntry />}
          />
          <Route
            path={`/home/forms/${TemporaryExemptionPropertyDamagedbyDisasterFormName}/`}
            element={<ExemptionPropertyDamagedForm />}
          />
          <Route
            path={`/home/forms/${TemporaryExemptionPropertyDamagedbyDisasterFormName}/form-entry/:formEntryIdParams`}
            element={<ExemptionPropertyDamagedFormEntry />}
          />
          <Route
            path={`/home/forms/${DisabledVetFormName}/`}
            element={<DisabledVetForm />}
          />
          <Route
            path={`/home/forms/${DisabledVetFormName}/form-entry/:formEntryIdParams`}
            element={<DisabledVetFormEntry />}
          />
          <Route
            path={`/home/forms/${TimberlandAppraisalFormName}/`}
            element={<TimberlandAppraisalForm />}
          />
          <Route
            path={`/home/forms/${TimberlandAppraisalFormName}/form-entry/:formEntryIdParams`}
            element={<TimberlandAppraisalFormEntry />}
          />
          {!isAdmin && (
            <>
              <Route path="/home/form-entries" element={<FormEntries />} />
              <Route
                path="/home/form-entry/:formEntryId"
                element={<FormEntry />}
              />
              <Route path="/home/dialogs/:searchType" element={<Dialogs />} />
              <Route path="/home/alerts" element={<Alerts />} />

              <Route path="*" element={<NotFound />} />
            </>
          )}
          {isAuthenticated && (
            <Route path="/home/download/:attachmentId" element={<Download />} />
          )}
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/activate-account" element={<AccountActivation />} />
        </Route>

        {isAdmin && (
          <Route path="/admin" element={<Admin />}>
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/alerts" element={<Alerts />} />
            <Route path="/admin/forms/:id" element={<Forms />} />
            <Route path="/admin/dialogs/:searchType" element={<Dialogs />} />
            <Route path="/admin/users" element={<Users />}>
              <Route path="/admin/users/admins" element={<Users />} />
              <Route path="/admin/users/propertyOwners" element={<Users />} />
            </Route>
            <Route
              path="/admin/permissions/site-level"
              element={<SitePermissions />}
            />
            <Route
              path="/admin/permissions/form-level"
              element={<FormPermissions />}
            />
            <Route path="/admin/user-groups" element={<UserGroup />} />
            <Route path="/admin/settings" element={<Settings />} />

            <Route path="*" element={<NotFound />} />
          </Route>
        )}
      </Routes>
    </Suspense>
  );
}
