import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./FreeportForm.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input, Checkbox, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { IFreeportFormFourthSectionInterface } from "src/interfaces/forms/FreeportForm/freeport-form-fourth-section.interface";
import { PercentageFormatOptions, USDCurrencyFormatOptions } from "src/pages/Shared/NumberFormatHelper/NumberFormatHelper";
import { CustomDatePicker } from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import { CheckboxGroup } from "../CustomInputs/CheckBoxes/CheckboxGroup";
import { CustomOnlyYearCalendarHelper } from "src/pages/Shared/DatePickers/CustomCalendars";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IFreeportFormFourthSectionInterface) => void;
    formDataFields?:  IFreeportFormFourthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function FreeportFormFourthSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid, isAdminPreview } = props;
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [totalCostOfGoodsYearInputValid, setTotalCostOfGoodsYearInputValid] = useState<boolean>(false);
    const [inventoryWillBeTransportedOutOfStateInputValid, setInventoryWillBeTransportedOutOfStateInputValid] = useState<boolean>(false);
    const [hasAppliedForSept1InventoryAppraisalInputValid, setHasAppliedForSept1InventoryAppraisalInputValid] = useState<boolean>(false);
    const [portionsOfInventoryTransportedThroughoutLastYearInputValid, 
        setPortionsOfInventoryTransportedThroughoutLastYearInputValid] = useState<boolean>(false);
    const [percentageOfGoodsTransportedThisYearInputValid, setPercentageOfGoodsTransportedThisYearInputValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={4} name={"Questions About Your Property"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | TextAreaChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            } as IFreeportFormFourthSectionInterface);
        } else if (field == "TotalCostOfGoodsYear"){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value ? event.value.getFullYear().toString() : undefined
            });
        } else if (field === "TotalCostOfGoodsSoldForYear" && formDataFields?.TotalCostOfGoodsShippedOutOfTexas && event.value) {
            setFormDataFields({
                ...formDataFields,
                PercentageOfLastYearsValue: (formDataFields.TotalCostOfGoodsShippedOutOfTexas / +event.value )*100,
                [field]: event.value
            })
        } else if (field === "TotalCostOfGoodsShippedOutOfTexas" && formDataFields?.TotalCostOfGoodsSoldForYear && event.value) {
            setFormDataFields({
                ...formDataFields,
                PercentageOfLastYearsValue: (+event.value / formDataFields.TotalCostOfGoodsSoldForYear)*100 ,
                [field]: event.value
            })
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            } as IFreeportFormFourthSectionInterface);
        }
    }

    const typesOfRecordsValidator = () => {
        return !!(formDataFields?.TypesOfRecords);
      };
    
    function changeValidator() {
        if (formRef.current) {
            const isValid = typesOfRecordsValidator() &&
                totalCostOfGoodsYearInputValid &&
                inventoryWillBeTransportedOutOfStateInputValid &&
                hasAppliedForSept1InventoryAppraisalInputValid &&
                portionsOfInventoryTransportedThroughoutLastYearInputValid &&
                percentageOfGoodsTransportedThisYearInputValid &&
                formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        totalCostOfGoodsYearInputValid,
        inventoryWillBeTransportedOutOfStateInputValid,
        hasAppliedForSept1InventoryAppraisalInputValid,
        portionsOfInventoryTransportedThroughoutLastYearInputValid,
        percentageOfGoodsTransportedThisYearInputValid
    ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <Label className={classes.Label}>
                                    1. Will portions of this inventory be transported out of state this year? 
                                    {!inventoryWillBeTransportedOutOfStateInputValid && (
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    )}
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"InventoryWillBeTransportedOutOfState"}
                                            fieldSecondName={"InventoryWillBeTransportedOutOfStateSecond"}
                                            isRequired={true}
                                            checkboxesIsValid={inventoryWillBeTransportedOutOfStateInputValid}
                                            setCheckboxesIsValid={setInventoryWillBeTransportedOutOfStateInputValid}
                                        />
                                        {!inventoryWillBeTransportedOutOfStateInputValid && (
                                            <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <Label className={`${classes.Label}`}>
                                    2. Have you applied for Sept. 1 inventory appraisal 
                                    {!hasAppliedForSept1InventoryAppraisalInputValid && (
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    )}
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"HasAppliedForSept1InventoryAppraisal"}
                                            fieldSecondName={"HasAppliedForSept1InventoryAppraisalSecond"}
                                            isRequired={true}
                                            checkboxesIsValid={hasAppliedForSept1InventoryAppraisalInputValid}
                                            setCheckboxesIsValid={setHasAppliedForSept1InventoryAppraisalInputValid}
                                        />
                                        {!hasAppliedForSept1InventoryAppraisalInputValid && (
                                            <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.bordered}>
                                <div className={classes.itemFields}>
                                    <Label className={`${classes.Label}`}>
                                        3. Were portions of your inventory transported out of this state throughout last year?      
                                        {!portionsOfInventoryTransportedThroughoutLastYearInputValid && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"PortionsOfInventoryTransportedThroughoutLastYear"}
                                                fieldSecondName={"PortionsOfInventoryTransportedThroughoutLastYearSecond"}
                                                isRequired={true}
                                                checkboxesIsValid={portionsOfInventoryTransportedThroughoutLastYearInputValid}
                                                setCheckboxesIsValid={setPortionsOfInventoryTransportedThroughoutLastYearInputValid}
                                            />
                                            {!hasAppliedForSept1InventoryAppraisalInputValid && (
                                                <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                { formDataFields?.PortionsOfInventoryTransportedThroughoutLastYear === false &&
                                    <div className={`${classes.flexRowReverse}`}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                If no, because inventory was transported only part of year, give the months during which 
                                                portions of your inventory were transported out of the state last year:
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={true}
                                                name={"PortionsOfInventoryTransportedMonths"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PortionsOfInventoryTransportedMonths}
                                                onChange={(e)=> changeValueHandler(e, "PortionsOfInventoryTransportedMonths")}
                                            />
                                            {!inputValidator("PortionsOfInventoryTransportedMonths") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <div className={`${classes.fieldsGroupW75} ${classes.flexRow}`}>
                                    <Label className={classes.Label}>
                                        4. Total cost of goods sold for the entire year ending Dec. 31, &nbsp;
                                    </Label>
                                    <span className={classes.fieldsGroupW25}>
                                        <CustomDatePicker
                                            fieldName={"TotalCostOfGoodsYear"}
                                            required={true}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={true}
                                            CustomCalendar={CustomOnlyYearCalendar}
                                            setIsValidField={setTotalCostOfGoodsYearInputValid}
                                            validField={totalCostOfGoodsYearInputValid}
                                            defaultValueDate={formDataFields?.TotalCostOfGoodsYear}
                                        />
                                    </span>
                                    <Label className={classes.Label}>
                                        {(!formDataFields?.TotalCostOfGoodsYear || !totalCostOfGoodsYearInputValid) && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                </div>
                                <div className={classes.fieldsGroupW25}>
                                    <NumericTextBox
                                        disabled={formSettings?.isReadOnly}
                                        required={true}
                                        className={classes.kTextbox}
                                        placeholder="Total Cost of Goods"
                                        value={formDataFields?.TotalCostOfGoodsSoldForYear}
                                        onChange={(e)=> changeValueHandler(e, "TotalCostOfGoodsSoldForYear")}
                                        format={USDCurrencyFormatOptions}
                                        spinners={false}
                                    />
                                    {!formDataFields?.TotalCostOfGoodsSoldForYear && (
                                        <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <div className={classes.fieldsGroupW70}>
                                    <Label className={classes.Label}>
                                        5. Total cost of goods sold that were shipped out of Texas within the applicable 
                                            time frame after acquisition or being brought into Texas last year, less the cost 
                                            of any goods, raw materials or supplies incorporated into them that were not 
                                            eligible for the freeport exemption or were in Texas for more than the applicable 
                                            number of days 
                                        {!formDataFields?.TotalCostOfGoodsShippedOutOfTexas && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                </div>
                                <div className={classes.fieldsGroupW25}>
                                    <NumericTextBox
                                        disabled={formSettings?.isReadOnly}
                                        required={true}
                                        className={classes.kTextbox}
                                        placeholder="Total Cost of Goods"
                                        value={formDataFields?.TotalCostOfGoodsShippedOutOfTexas}
                                        onChange={(e)=> changeValueHandler(e, "TotalCostOfGoodsShippedOutOfTexas")}
                                        format={USDCurrencyFormatOptions}
                                        spinners={false}
                                    />
                                    {!formDataFields?.TotalCostOfGoodsShippedOutOfTexas && (
                                        <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <div className={classes.fieldsGroup}>
                                    <Label className={classes.Label}>
                                        6. On what types of records do you base the amounts given above? (Check all that apply.)
                                        {!typesOfRecordsValidator() && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            name={"AuditedFinancialStatement"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.TypesOfRecords?.AuditedFinancialStatement}
                                            label={"Audited financial statement"}
                                            onChange={(e)=> changeValueHandler(e, "AuditedFinancialStatement", "TypesOfRecords")}
                                        />
                                        <Checkbox
                                            name={"SalesRecords"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.TypesOfRecords?.SalesRecords}
                                            label={"Sales records"}
                                            onChange={(e)=> changeValueHandler(e, "SalesRecords", "TypesOfRecords")}
                                        />
                                        <Checkbox
                                            name={"InternalReports"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.TypesOfRecords?.InternalReports}
                                            label={"Internal reports"}
                                            onChange={(e)=> changeValueHandler(e, "InternalReports", "TypesOfRecords")}
                                        />
                                        <Checkbox
                                            name={"BillsOfLading"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.TypesOfRecords?.BillsOfLading}
                                            label={"Bills of lading"}
                                            onChange={(e)=> changeValueHandler(e, "BillsOfLading", "TypesOfRecords")}
                                        />
                                        <Checkbox
                                            name={"TexasFranchiseTaxReports"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.TypesOfRecords?.TexasFranchiseTaxReports}
                                            label={"Texas franchise tax reports"}
                                            onChange={(e)=> changeValueHandler(e, "TexasFranchiseTaxReports", "TypesOfRecords")}
                                        />
                                        <Checkbox
                                            name={"Other"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.TypesOfRecords?.Other}
                                            label={"Other (describe)"}
                                            onChange={(e)=> changeValueHandler(e, "Other", "TypesOfRecords")}
                                        />
                                        { formDataFields?.TypesOfRecords?.Other &&
                                            <div className="inputsGroup">
                                                <Label className={classes.Label}>
                                                    Other (describe)
                                                    {!formDataFields?.TypesOfRecordsOther && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <Input
                                                    className={setStyles("TypesOfRecordsOther")}
                                                    name={"TypesOfRecordsOther"}
                                                    required={formDataFields?.TypesOfRecords?.Other}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.TypesOfRecordsOther}
                                                    onChange={(e)=> changeValueHandler(e, "TypesOfRecordsOther")}
                                                />
                                                {!inputValidator("TypesOfRecordsOther") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div> }
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <div className={classes.fieldsGroupW75}>
                                    <Label className={classes.Label}>
                                        7. Percentage of last year’s value represented by freeport goods 
                                            (line 5 divided by line 4) 
                                        {!formDataFields?.PercentageOfLastYearsValue && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                </div>
                                <div className={classes.fieldsGroupW25}>
                                    <NumericTextBox
                                        disabled={true}
                                        required={true}
                                        className={classes.kTextbox}
                                        placeholder="Percentage of Last Year's Value"
                                        value={formDataFields?.PercentageOfLastYearsValue}
                                        onChange={(e)=> changeValueHandler(e, "PercentageOfLastYearsValue")}
                                        //format={PercentageFormatOptions}
                                        spinners={false}
                                    />
                                    {!formDataFields?.PercentageOfLastYearsValue && (
                                        <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <div className={classes.bordered}>
                                <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        8. Will the percentage of goods transported out of Texas this year be significantly 
                                            different than the percentage transported out last year? 
                                        {!percentageOfGoodsTransportedThisYearInputValid && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )} 
                                    </Label>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"PercentageOfGoodsTransportedThisYear"}
                                                fieldSecondName={"PercentageOfGoodsTransportedThisYearSecond"}
                                                isRequired={true}
                                                checkboxesIsValid={percentageOfGoodsTransportedThisYearInputValid}
                                                setCheckboxesIsValid={setPercentageOfGoodsTransportedThisYearInputValid}
                                            />
                                            {!hasAppliedForSept1InventoryAppraisalInputValid && (
                                                <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                { formDataFields?.PercentageOfGoodsTransportedThisYear && 
                                    <div className={`${classes.flexRowReverse}`}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                If yes, why?
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={true}
                                                name={"PortionsOfInventoryTransportedThroughoutLastYearIfYes"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PortionsOfInventoryTransportedThroughoutLastYearIfYes}
                                                onChange={(e)=> changeValueHandler(e, "PortionsOfInventoryTransportedThroughoutLastYearIfYes")}
                                            />
                                            {!inputValidator("PortionsOfInventoryTransportedThroughoutLastYearIfYes") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <div className={classes.fieldsGroupW75}>
                                    <Label className={classes.Label}>
                                        9. What was the market value of your inventory on Jan. 1 of this year, or Sept. 1 
                                            of last year if qualified for Sept. 1 inventory appraisal? 
                                        {!formDataFields?.MarketValueOfInventory && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                </div>
                                <div className={classes.fieldsGroupW25}>
                                    <NumericTextBox
                                        disabled={formSettings?.isReadOnly}
                                        required={true}
                                        className={classes.kTextbox}
                                        placeholder="Market Value"
                                        value={formDataFields?.MarketValueOfInventory}
                                        onChange={(e)=> changeValueHandler(e, "MarketValueOfInventory")}
                                        format={USDCurrencyFormatOptions}
                                        spinners={false}
                                    />
                                    {!formDataFields?.MarketValueOfInventory && (
                                        <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <div className={classes.fieldsGroupW75}>
                                    <Label className={classes.Label}>
                                        10. What is the value of the inventory you claim will be exempt this year? 
                                        {!formDataFields?.ValueOfExemptInventory && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                </div>
                                <div className={classes.fieldsGroupW25}>
                                    <NumericTextBox
                                        disabled={formSettings?.isReadOnly}
                                        required={true}
                                        className={classes.kTextbox}
                                        placeholder="Value of Inventory"
                                        value={formDataFields?.ValueOfExemptInventory}
                                        onChange={(e)=> changeValueHandler(e, "ValueOfExemptInventory")}
                                        format={USDCurrencyFormatOptions}
                                        spinners={false}
                                    />
                                    {!formDataFields?.ValueOfExemptInventory && (
                                        <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}