import AuthService from './services/authentication.service';
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { useEffect } from "react";
import { globalConfig } from "./configuration/config";
import { permissionData } from "./services/permission.service";

const authenticationService = AuthService.getInstance();

const instance = axios.create({
  baseURL: "",
  headers: {
    "Content-Type": "application/json",
  },
});

function addFormIdHeader(config: InternalAxiosRequestConfig<Headers>) {
  if (config.headers && permissionData.formId) {
    config.headers["FormId"] = permissionData.formId?.toString() ?? "";
  }
}

function setAuthAttribute(
  config: InternalAxiosRequestConfig<Headers>,
  accessToken: string | undefined
) {
  if (!accessToken) {
    return;
  }

  instance.defaults.headers.common.authorization = `Bearer ${accessToken}`;
  //(config.headers ??= {}).authorization = `Bearer ${accessToken}`;
}

const AxiosInterceptor = ({ children }: any) => {
  const saveAccessToken = async () => {
    await authenticationService.getToken()
      .then((token) => {
        if (token) {
          instance.defaults.headers.common.authorization = `Bearer ${token}`;
        }

        const interceptor = instance.interceptors.response.use(
          resInterceptor,
          errInterceptor
        );

        return () => instance.interceptors.response.eject(interceptor);
      })
      .catch(() => {
        const interceptor = instance.interceptors.response.use(
          resInterceptor,
          errInterceptor
        );

        return () => instance.interceptors.response.eject(interceptor);
      });
  }

  instance.defaults.baseURL = globalConfig.get().apiRoot;

  useEffect(() => {
    instance.interceptors.request.use(reqInterceptor, errInterceptor);
    saveAccessToken();
  }, []);

  const reqInterceptor = async (config: InternalAxiosRequestConfig<Headers>) => {
    const anonymousRoutes = ['/activate-account', '/reset-password', '/login'];
    if (anonymousRoutes.some(route => location.pathname.includes(route))) {
      return config;
    }

    addFormIdHeader(config);
    setAuthAttribute(config, await authenticationService.getToken());

    return config;
  };

  const resInterceptor = (response: any) => response;

  const errInterceptor = async (error: AxiosError) =>
    error.response?.status === 401
      ? handleUnauthorized(error)
      : Promise.reject(error);

  async function handleUnauthorized(error: AxiosError<any, any>) {
    await authenticationService.logout();
    return Promise.reject(error);
  }

  return children;
};

export default instance;
export { AxiosInterceptor };
