import {
  Log,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client";
import { globalConfig } from "../configuration/config";
import jwt from "jwt-decode";
import type { User as UserInterface } from "src/interfaces/user.interface";
import SignalrService from "./signalr.service";
import { settingsService } from "./settings.service";

export interface UserTokenInfo extends UserInterface {
  numeric_id: number;
}

export default class AuthService {
  static instance: AuthService;

  private _user: UserTokenInfo | undefined;
  private signalrService = SignalrService.getInstance();

  static getInstance() {
    if (AuthService.instance == null) {
      AuthService.instance = new AuthService();
    }

    return this.instance;
  }

  public authData: User | null = null;

  public async getToken(): Promise<string | undefined> {
    return localStorage.getItem('access_token') || undefined;
  }

  public async login(returnUrl?: string): Promise<void> {
    const { settings } = settingsService();

    if (settings.enableOPLoginIntegration) {
      location.assign(settings.onlinePortalURL);
      return;
    }

    const userManager = this.getUserManager();

    let redirectUri = userManager.settings.redirect_uri;

    return userManager.signinRedirect({ redirect_uri: redirectUri });
  }

  public register() {
    const { settings } = settingsService();

    if (settings.enableOPLoginIntegration) {
      location.assign(settings.onlinePortalURL.replace(/\/$/, '') + '/Account/Register');
      return;
    }

    window.location.assign(`${globalConfig.get().applicationManagementPortalRoot}/Account/RegisterPropertyOwner`);
  }

  public async logout(): Promise<void> {
    const { settings } = settingsService();

    localStorage.removeItem("access_token");
    this._user = undefined;
    this.authData = null;
    this.signalrService.stop();

    if (settings.enableOPLoginIntegration) {
      location.assign(location.origin);
      return;
    }

    return this.getUserManager().signoutRedirect();
  }

  private getUserManager() {
    return new UserManager({
      authority: globalConfig.get().applicationManagementPortalRoot,
      client_id: "of",
      redirect_uri: `${globalConfig.get().uiRoot}/signin-callback`,
      response_type: "id_token token",
      scope: "openid profile of",
      post_logout_redirect_uri: `${globalConfig.get().uiRoot}/login`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${globalConfig.get().uiRoot}/silent-refresh.html`,
      userStore: new WebStorageStateStore({ store: localStorage }),
      stateStore: new WebStorageStateStore({ store: localStorage }),
      monitorSession: false,
    });
  }

  public clearUser = (): void => {
    this._user = undefined;
    this.authData = null;
  };

  public setUserFromOidc(accessToken: string): void {
    const userInfo = jwt(accessToken) as UserTokenInfo;
    localStorage.setItem('access_token', accessToken);
    this.setUser(userInfo);
  }

  public setUser(userInfo: UserTokenInfo): void {
    userInfo.id = parseInt(userInfo.numeric_id.toString()); // numeric_id comes as string for some reason
    userInfo.isAdmin = userInfo.isAdmin?.toString().toLowerCase() === "true";
    userInfo.isSuperAdmin =
      userInfo.isSuperAdmin?.toString().toLowerCase() === "true";
    this._user = userInfo;
  }

  public get isAuthorized(): boolean {
    return !!this._user;
  }

  public async user(): Promise<UserTokenInfo | undefined> {
    if (this._user) {
      return this._user;
    }

    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      return undefined;
    }

    this.setUserFromOidc(accessToken);

    return this._user;
  }

  /**
   * Check if the given path is a path that does not require sign-in to view
   */
  public isUnathenticatedPath(path: string, search: string) {
    if ([
      "/login",
      "/signin-callback",
      "/signout-callback",
    ].includes(path)) {
      return true;
    }

    // unauthenticated users are allowed to look at forms but they're read-only
    // but if the search query is non-empty this is possibly a form open from an external source (Property Search) in which
    // case, sign in is required
    if (path.startsWith('/home/forms/') && !search) {
      return true;
    }
  }

  public saveCurrentPath() {
    localStorage.setItem("redirect_url", `${location.pathname}${location.search}`);
  }

  public getSavedPath() {
    return localStorage.getItem('redirect_url')
  }

  public clearSavedPath() {
    localStorage.removeItem("redirect_url");
  }
}
